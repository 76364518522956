import React from 'react';
import { Link } from 'react-router-dom'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


class NavMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (target) => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        {/* <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
           
        >
          Open Menu
        </Button> */}
         <IconButton 
           // className={classes.menuButton} 
            color="inherit" 
            aria-label="Menu" 
            onClick={this.handleClick}  
            aria-owns={anchorEl ? 'nav-menu' : undefined}
            aria-haspopup="true">
            <MenuIcon />
          </IconButton>
        <Menu
          id="nav-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem  component={Link} to="/" name="Home">Home</MenuItem>
          <MenuItem  component={Link} to="/dashboard" name="Dashboard">Dashboard</MenuItem>
          <MenuItem  component={Link} to="/about" name="About">About</MenuItem>
          <MenuItem  component={Link} to="/logout" name="Logout">Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default NavMenu;