import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload' 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';


const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    iconSmall: {
      fontSize: 20,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
    //   formControl: {
    //     margin: theme.spacing.unit * 3,
    //   },
      group: {
        margin: `${theme.spacing.unit}px 0`,
      },
      formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing.unit * 2,
      },
  });

  class AddParticipationHours extends React.Component {
     constructor() {
         super();

         this.state = {
            open: false,
            selectedTimeOption: 'hours',
            amount: 0,
            category: ''
          };
     }


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = event => {
        this.setState({selectedTimeOption: event.target.value});
    }

    handleCategoryChange = event => {
         
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClickOpen}>
                    Add Participation Hours
                    <CloudUploadIcon className={classes.rightIcon} />
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    >
                    <DialogTitle id="form-dialog-title">Add Hours</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                         Please select the category, select the date, and enter the amount of time in hours or minutes.
                        </DialogContentText>
                        <form className={classes.root} autoComplete="off">
                        <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Date"
                            type="date"
                            defaultValue="2019-05-24"
                        />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                        <RadioGroup
                            aria-label="Hours"
                            name="hours1"
                            className={classes.group}
                            value={this.state.value}
                            onChange={this.handleChange}
                        >
                            <FormControlLabel value="hours" control={<Radio />} label="Hours" />
                            <FormControlLabel value="minutes" control={<Radio />} label="Minutes" />
                           
                        </RadioGroup>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            label="Amount"
                            type="text"
                            
                        />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="category-select">Category</InputLabel>
                        <Select
                            value={this.state.category}
                            onChange={this.handleCategoryChange}
                            inputProps={{
                            name: 'category',
                            id: 'category-select',
                            }}
                        >
                            <MenuItem value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="notes"
                            label="Notes"
                            type="notes"
                            fullWidth
                        />
                        </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                        Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                        Save
                        </Button>
                    </DialogActions>
                    </Dialog>
                    
            </div>
        );
     }
  }

  export default withStyles(styles)(AddParticipationHours);