import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

//Test

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import GavelIcon from '@material-ui/icons/Gavel';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import DenyIcon from '@material-ui/icons/NotInterested'

import AddParticipationHours from './AddParticipationHours'; 


const TestData = {
  "data": [
      {
          "DT_RowId": 23309,
          "status": 0,
          "occurredat": "10/19/18",
          "duration": "0.50",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "test",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2018-Oct-24 05:56 (Wed)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "",
          "approvedby": ""
      },
      {
          "DT_RowId": 23438,
          "status": 0,
          "occurredat": "10/26/18",
          "duration": "5.00",
          "catname": "WebSite",
          "catid": 12,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "hjh",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2018-Oct-26 22:57 (Fri)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "",
          "approvedby": ""
      },
      {
          "DT_RowId": 23536,
          "status": 1,
          "occurredat": "10/19/18",
          "duration": "5.00",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "hhh",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2018-Oct-29 18:54 (Mon)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2018-Nov-29 01:43 (Thu)",
          "approvedby": "Rosario Aguila"
      },
      {
          "DT_RowId": 23700,
          "status": 1,
          "occurredat": "11/03/18",
          "duration": "1.25",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "bulk add test",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2018-Nov-05 20:47 (Mon)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2018-Nov-05 20:47 (Mon)",
          "approvedby": "Artur Rodrigues"
      },
      {
          "DT_RowId": 23707,
          "status": 1,
          "occurredat": "11/04/18",
          "duration": "0.33",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "bulk2",
          "fullname": "Artur2 Rodrigues",
          "email": "arturlr@gmail.com",
          "owner": false,
          "submittedat": "2018-Nov-06 06:11 (Tue)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2018-Nov-06 06:11 (Tue)",
          "approvedby": "Artur Rodrigues"
      },
      {
          "DT_RowId": 23708,
          "status": 1,
          "occurredat": "11/04/18",
          "duration": "0.25",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "bulk2",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2018-Nov-06 06:11 (Tue)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2018-Nov-29 01:43 (Thu)",
          "approvedby": "Rosario Aguila"
      },
      {
          "DT_RowId": 24929,
          "status": 1,
          "occurredat": "01/18/19",
          "duration": "0.50",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "test bulk",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2019-Jan-25 05:07 (Fri)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2019-Jan-25 05:07 (Fri)",
          "approvedby": "Artur Rodrigues"
      },
      {
          "DT_RowId": 24932,
          "status": 1,
          "occurredat": "01/18/19",
          "duration": "0.50",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "test bulk",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2019-Jan-25 05:14 (Fri)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2019-Jan-25 05:14 (Fri)",
          "approvedby": "Artur Rodrigues"
      },
      {
          "DT_RowId": 24933,
          "status": 1,
          "occurredat": "01/24/19",
          "duration": "0.25",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "bulktest2",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": true,
          "submittedat": "2019-Jan-25 19:46 (Fri)",
          "submittedby": "7ff5253f-aa47-4b6c-acc7-19dce757d325",
          "approvedat": "2019-Jan-25 19:46 (Fri)",
          "approvedby": "Artur Rodrigues"
      },
      {
          "DT_RowId": 29266,
          "status": 1,
          "occurredat": "05/01/19",
          "duration": "70.00",
          "catname": "Special Assignments",
          "catid": 21,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "Participation Hours",
          "fullname": "Artur Rodrigues",
          "email": "arturlr@live.com",
          "owner": false,
          "submittedat": "2019-May-02 00:13 (Thu)",
          "submittedby": "f9a9d4fa-f84a-48dc-9402-d2e7020e54d9",
          "approvedat": "2019-May-02 00:13 (Thu)",
          "approvedby": "Riza Racho"
      },
      {
          "DT_RowId": 29341,
          "status": 1,
          "occurredat": "05/03/19",
          "duration": "1.00",
          "catname": "Participation Hours Tracker System Support",
          "catid": 45,
          "clubname": "RODRIGUES",
          "clubid": 1,
          "clubcode": "R0115",
          "notes": "Test",
          "fullname": "Ryan Jaeger",
          "email": "rjjaeger@gmail.com",
          "owner": true,
          "submittedat": "2019-May-03 22:14 (Fri)",
          "submittedby": "ae770722-e523-442e-8cb5-2ef02e11878a",
          "approvedat": "2019-May-03 22:14 (Fri)",
          "approvedby": "Artur Rodrigues"
      }
  ]
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'user', numeric: false, disablePadding: true, label: 'User' },
  { id: 'occurredat', numeric: false, disablePadding: false, label: 'Occurred At' },
  { id: 'category', numeric: false, disablePadding: false, label: 'Category' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', icon: 'gavel' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Duration' },
  { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  getIcon(name) {
    if (name === "gavel") {
      return <GavelIcon/>;
    }
    return "";
  }

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                   {row.icon != null ? this.getIcon(row.icon) : row.label}

                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Participation Details
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <table>
            <tbody>
              <tr>
                <td>
          <Tooltip title="Approve">
            <IconButton aria-label="Approve">
              <DoneIcon nativeColor="green" />
            </IconButton>
          </Tooltip>
          </td>
          <td>
           <Tooltip title="Deny">
           <IconButton aria-label="Deny">
             <DenyIcon nativeColor="red" />
           </IconButton>
         </Tooltip>
         </td>
         </tr>
         </tbody>
         </table>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

class EnhancedTable extends React.Component {
  constructor() {
    super();
   
    let tmp = TestData.data.map( x=> {
      let y = x;
      y.id = x.DT_RowId
      return y
  });

    this.state = {
      order: 'desc',
      orderBy: 'occurredat',
      selected: [],
      data: tmp,
      page: 0,
      rowsPerPage: 10,
    };

   
  }
  
 

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  getStatusIcon(status) {
    if (status === 0) {
      return <WarningIcon nativeColor="orange"/>
    } else if (status === 1) {
      return <DoneIcon nativeColor="green"/>
    }
    return "";
  }

  render() {
   
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>

        <AddParticipationHours />
       
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.fullname}
                      </TableCell>
                      <TableCell align="left">{n.occurredat}</TableCell>
                      <TableCell align="left">{n.catname}</TableCell>
                      <TableCell align="left">{this.getStatusIcon(n.status)}</TableCell>
                      <TableCell align="right">{n.duration}</TableCell>
                      <TableCell align="left">{n.notes}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);