import React from 'react';
import { PieChart, Pie, Tooltip } from 'recharts';
import { ResponsivePie } from '@nivo/pie';
import Grid from '@material-ui/core/Grid';

const SampleData = {
    "bycategory": [
        {
            "name": "Participation Hours Tracker System Support",
            "pending": "0.50",
            "approved": "9.08"
        },
        {
            "name": "Special Assignments",
            "pending": "0.00",
            "approved": "70.00"
        },
        {
            "name": "WebSite",
            "pending": "5.00",
            "approved": "0.00"
        }
    ]
};

class ApprovedHoursChart extends React.Component{
    constructor() {
        super();

      
       

        let totalPending = SampleData.bycategory.reduce((total, current) =>{
            return total +  parseFloat(current.pending);
        }, 0.0);

       

        const aData = this.getApprovedSampleData();
        const pData = this.getPendingSampleData();

console.log(JSON.stringify(aData));

        this.state = {
            approvedData: aData,
            pendingData: pData
        };

       
    }

    getApprovedSampleData() {
        let totalApproved = SampleData.bycategory.reduce((total, current) =>{
            return total +  parseFloat(current.approved);
        }, 0.0);

        const aData = SampleData.bycategory.map( x => {
            let tmpObject = 
            ({
                "id": x.name,
                "label": x.name,
                "value": ((parseFloat(x.approved) / totalApproved) * 100).toFixed(2)
            }); 
          
            return tmpObject;
        }); 

        return aData;
    }

    getPendingSampleData() {
        let totalPending = SampleData.bycategory.reduce((total, current) =>{
            return total +  parseFloat(current.pending);
        }, 0.0);

        const aData = SampleData.bycategory.map( x => {
            let tmpObject = 
            ({
                "id": x.name,
                "label": x.name,
                "value": ((parseFloat(x.pending) / totalPending) * 100).toFixed(2)
            }); 
          
            return tmpObject;
        }); 

        return aData;
    }


    render() {
       return (
       <Grid container spacing={12}>
            <Grid item xs={6}>
            <h1>Approved Hours</h1>
            <div style={{ height: '400px'}}>
                <ResponsivePie
                data={this.state.approvedData}
                margin={{
                    "top": 40,
                    "right": 80,
                    "bottom": 80,
                    "left": 80
                    }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{
                        "scheme": "nivo"
                    }}
                    borderWidth={1}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[
                        {
                            "anchor": "bottom-right",
                            "direction": "column",
                            "translateY": 56,
                            "itemWidth": 100,
                            "itemHeight": 18,
                            "itemTextColor": "#999",
                            "symbolSize": 18,
                            "symbolShape": "circle",
                            "effects": [
                                {
                                    "on": "hover",
                                    "style": {
                                        "itemTextColor": "#000"
                                    }
                                }
                            ]
                        }
                    ]}
                />
                </div>
    
            </Grid>
            <Grid item xs={6}>
            
            <h1>Pending Hours</h1>
            <div style={{ height: '400px' }}>
                <ResponsivePie
                data={this.state.pendingData}
                margin={{
                    "top": 40,
                    "right": 80,
                    "bottom": 80,
                    "left": 80
                    }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{
                        "scheme": "nivo"
                    }}
                    borderWidth={1}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[
                        {
                            "anchor": "bottom-right",
                            "direction": "column",
                            "translateY": 56,
                            "itemWidth": 100,
                            "itemHeight": 18,
                            "itemTextColor": "#999",
                            "symbolSize": 18,
                            "symbolShape": "circle",
                            "effects": [
                                {
                                    "on": "hover",
                                    "style": {
                                        "itemTextColor": "#000"
                                    }
                                }
                            ]
                        }
                    ]}
                />
                </div>
    
            </Grid>
        </Grid>
       );
    }
}

export default ApprovedHoursChart;