import React from 'react';
import ClubHoursTable from './ClubHoursTable'

class Home extends React.Component {
    render() {
        return (
            <ClubHoursTable></ClubHoursTable>
        );
    }
}


export default Home;