import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';


const SampleData = {
    "bymonth": [
        {
            "monthyear": "Jul-2018",
            "pending": "0",
            "approved": "0"
        },
        {
            "monthyear": "Aug-2018",
            "pending": "0",
            "approved": "0"
        },
        {
            "monthyear": "Sep-2018",
            "pending": "0",
            "approved": "0"
        },
        {
            "monthyear": "Oct-2018",
            "pending": "5.50",
            "approved": "5.00"
        },
        {
            "monthyear": "Nov-2018",
            "pending": 0,
            "approved": "1.83"
        },
        {
            "monthyear": "Dec-2018",
            "pending": 0,
            "approved": 0
        },
        {
            "monthyear": "Jan-2019",
            "pending": 0,
            "approved": "1.25"
        },
        {
            "monthyear": "Feb-2019",
            "pending": 0,
            "approved": 0
        },
        {
            "monthyear": "Mar-2019",
            "pending": "0",
            "approved": "0"
        },
        {
            "monthyear": "Apr-2019",
            "pending": 0,
            "approved": 0
        },
        {
            "monthyear": "May-2019",
            "pending": 0,
            "approved": "71.00"
        },
        {
            "monthyear": "Jun-2019",
            "pending": "0",
            "approved": "0"
        }
    ]
};

class HoursSubmittedChart extends React.Component {
    constructor() {
        super();

    }

    render() {
        return (
            <Grid container>
            <Grid item md>
                <h1>Hours Submitted</h1>
                        <BarChart
                        width={500}
                        height={300}
                        data={SampleData.bymonth}
                        margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="monthyear" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pending" stackId="a" fill="#8884d8" />
                        <Bar dataKey="approved" stackId="a" fill="#82ca9d" />
                    </BarChart>
                </Grid>
            </Grid>
        );
    }
}

export default HoursSubmittedChart;