import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppBar from './components/AppBar'

import Home from './components/Home'
import About from './components/About'
import Dashboard from './components/Dashboard'

class App extends React.Component {
  render() {
    return (     
      <div className="App">
        <Router>
          <AppBar></AppBar>
          <div>
            <Route path="/" exact component={Home} />
            <Route path="/dashboard/" component={Dashboard} />
            <Route path="/about/" component={About} />
          </div>
      </Router>
      </div>
    );
  }
}

export default App;
