import React from 'react';
import Grid from '@material-ui/core/Grid';

import ApprovedHoursChart from './ApprovedHoursChart';
import HoursSubmittedChart from './HoursSubmittedChart';


class Dashboard extends React.Component {

    render() {
        return (
            <Grid container >
                <Grid item md>
                    <HoursSubmittedChart />
                </Grid>
                <Grid item md={12}>
                   <ApprovedHoursChart />
                </Grid>
          </Grid>
        );
    }
}


export default Dashboard;